import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout, LWrap } from "../../../components/_index"
import BusinessLayout from "../../../components/_extra/business_layout"

// markup
const SubPage = ({ location }: any) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/business_hotelstop/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
      },
    })
      .then(res => res.json())
      .then(res => setData({ res }));
  }, []);

  if (data === null) {
    return null;
  }

  const basebusiness = data.res.basicauth ? 'business' : 'rphs'

  return (
    <Layout isKv={false}>
      <SEO title="プレビュー"
        description="プレビュー"
        ogtype={"blog"}
      />
      <section>
        <LWrap>
          <p>案内URL ： https://www.royalparkhotels.co.jp/{basebusiness}/{contentId}/</p>
        </LWrap>
      </section>
      <BusinessLayout data={data.res} />
    </Layout>
  )
}

export default SubPage;
